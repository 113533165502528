<template>
  <div class="d-flex flex-column gap-7 gap-lg-10" >
    <div class="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
      <div class="post d-flex flex-column-fluid" id="kt_post">
        <!--begin::Container-->
        <div id="kt_content_container" class="container-xxl">
          <!--begin::div-->
          <div id="kt_ecommerce_add_product_form" class="form d-flex flex-column flex-lg-row">
            
            <!--begin::Main column-->
            <div class="d-flex flex-column flex-row-fluid gap-7 gap-lg-10" v-loading="fullscreenLoading">
              <!--begin::General-->
              <div class="card" v-if="orderProductDetails.value">
                <div class="card-header border-0 pt-6">
                  <div class="d-flex flex-column">
                    <div class="d-flex align-items-center mb-2 float-left">
                      <span class="text-gray-900 fs-2 fw-bolder me-1">{{ orderProductDetails.value.product_name }}</span>
                    </div>
                    <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 float-left">
                      <span class="d-flex align-items-center text-gray-400 me-5 mb-2">{{ orderProductDetails.value.product_sku }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card card-flush py-4">
                <!--begin::Card body-->
                <div class="card-body pt-0">
                  <div class="mb-10 fv-row">
                    <table class="table table-flush fw-bold gy-1">
                      <tr>
                        <td width="20%" style="vertical-align: top;">
                           <h2>Preview</h2>
                          <el-image v-if="orderProductDetails.value" style="width: 220px; height: 220px" :src="orderProductDetails.value.product_image[0]" :preview-src-list="orderProductDetails.value.product_image" :initial-index="0" fit="cover" class="w-220px h-250px">
                            <template #error>
                              <div class="image-slot">
                                <el-image style="width: 220; height: 220" :src="'/media/no-img.jpg'" fit="cover" />
                              </div>
                            </template>
                          </el-image>
                        </td>
                        <td width="80%" style="vertical-align: top;">
                          <table class="table table-flush fw-bold gy-1">
                            <tbody>
                              <tr v-if="orderProductDetails.value && orderProductDetails.value.separated_product_sku">
                                <td class="text-muted min-w-200px w-200px">Product SKU : </td>
                                <td class="text-gray-800">
                                  <el-tooltip class="box-item" effect="dark" :content="orderProductDetails.value.sku_status == 'Matched' ? 'Mapped with: ' + orderProductDetails.value.reference_sku_code : orderProductDetails.value.sku_status == 'Unmatched' ? 'SKU not found in system!' : 'SKU rejected'" placement="top" ><span :class="orderProductDetails.value.sku_status == 'Matched' ? 'badge badge-light-success' : orderProductDetails.value.sku_status == 'Unmatched' ? 'badge badge-light-warning' : 'badge badge-light-danger'">{{ orderProductDetails.value.separated_product_sku }}</span></el-tooltip>
                                </td>
                              </tr>
                              <tr v-if="orderProductDetails.value && orderProductDetails.value.separated_art_sku != NULL">
                                <td class="text-muted min-w-200px w-200px">Art SKU : </td>
                                <td class="text-gray-800">
                                  <el-tooltip class="box-item" effect="dark" :content="orderProductDetails.value.art_sku_status == 'Matched' ? 'Mapped with: ' + orderProductDetails.value.reference_sku_code : orderProductDetails.value.art_sku_status == 'Unmatched' ? 'Art SKU not found in system!' : 'SKU rejected'" placement="top" ><span :class="orderProductDetails.value.art_sku_status == 'Matched' ? 'badge badge-light-success' : orderProductDetails.value.art_sku_status == 'Unmatched' ? 'badge badge-light-warning' : 'badge badge-light-danger'">{{ orderProductDetails.value.separated_art_sku }}</span></el-tooltip>
                                </td>
                              </tr>
                              <tr v-if="orderProductDetails.value && orderProductDetails.value.product_size_label">
                                <td class="text-muted min-w-200px w-200px">Size Label : </td>
                                <td class="text-gray-800"> {{orderProductDetails.value.product_size_label}} </td>
                              </tr>
                              <tr v-if="orderProductDetails.value && orderProductDetails.value.product_quantity">
                                <td class="text-muted min-w-200px w-200px">Quantity : </td>
                                <td class="text-gray-800"> {{orderProductDetails.value.product_quantity}} </td>
                              </tr>
                              <tr v-if="orderProductDetails.value && orderProductDetails.value.product_width">
                                <td class="text-muted min-w-200px w-200px">Width : </td>
                                <td class="text-gray-800"> {{orderProductDetails.value.product_width}} </td>
                              </tr>
                              <tr v-if="orderProductDetails.value && orderProductDetails.value.product_height">
                                <td class="text-muted min-w-200px w-200px">Height : </td>
                                <td class="text-gray-800"> {{orderProductDetails.value.product_height}} </td>
                              </tr>
                              <tr v-if="orderProductDetails.value && orderProductDetails.value.product_weight">
                                <td class="text-muted min-w-200px w-200px">Weight : </td>
                                <td class="text-gray-800"> {{orderProductDetails.value.product_weight}} </td>
                              </tr>
                              <tr v-if="orderProductDetails.value && orderProductDetails.value.product_unit">
                                <td class="text-muted min-w-200px w-200px">Unit : </td>
                                <td class="text-gray-800"> {{orderProductDetails.value.product_unit}} </td>
                              </tr>
                              <tr v-if="orderProductDetails.value && orderProductDetails.value.product_price">
                                <td class="text-muted min-w-200px w-200px">Price : </td>
                                <td class="text-gray-800"> {{orderProductDetails.value.product_price}} </td>
                              </tr>
                              <tr v-if="orderProductDetails.value && orderProductDetails.value.product_shipping_price">
                                <td class="text-muted min-w-200px w-200px">Shipping Price : </td>
                                <td class="text-gray-800"> {{orderProductDetails.value.product_shipping_price}} </td>
                              </tr>
                              <tr v-if="orderProductDetails.value && orderProductDetails.value.product_discount">
                                <td class="text-muted min-w-200px w-200px">Discount : </td>
                                <td class="text-gray-800"> {{orderProductDetails.value.product_discount}} </td>
                              </tr>
                              <tr v-if="orderProductDetails.value && orderProductDetails.value.product_tax">
                                <td class="text-muted min-w-200px w-200px">Tax : </td>
                                <td class="text-gray-800"> {{orderProductDetails.value.product_tax}} </td>
                              </tr>
                              <tr v-if="orderProductDetails.value && orderProductDetails.value.product_subtotal">
                                <td class="text-muted min-w-200px w-200px">Sub Total : </td>
                                <td class="text-gray-800"> {{orderProductDetails.value.product_subtotal}} </td>
                              </tr>
                              <tr v-if="orderProductDetails.value && orderProductDetails.value.product_production_days">
                                <td class="text-muted min-w-200px w-200px">Production Days : </td>
                                <td class="text-gray-800"> {{orderProductDetails.value.product_production_days}} </td>
                              </tr>
                              <tr v-if="orderProductDetails.value && orderProductDetails.value.product_shipping_days">
                                <td class="text-muted min-w-200px w-200px">Shipping Days : </td>
                                <td class="text-gray-800"> {{orderProductDetails.value.product_shipping_days}} </td>
                              </tr>
                              <tr v-if="orderProductDetails.value && orderProductDetails.value.order_product_status && orderProductDetails.value.order_product_status.title">
                                <td class="text-muted min-w-200px w-200px">Status : </td>
                                <td class="text-gray-800"> <span :class="'badge badge-light-'+ orderProductDetails.value.order_product_status.badge">{{orderProductDetails.value.order_product_status.title}} </span></td>
                              </tr>
                              <tr v-if="orderProductDetails.value && orderProductDetails.value.production_due_date">
                                <td class="text-muted min-w-200px w-200px">Due Date : </td>
                                <td class="text-gray-800"> {{orderProductDetails.value.production_due_date}} </td>
                              </tr>
                              <tr v-if="orderProductDetails.value && orderProductDetails.value.downloaded_files && orderProductDetails.value.downloaded_files != ''">
                                <td class="text-muted">Product Files : </td>
                                <td v-if="orderProductDetails.value && orderProductDetails.value.downloaded_files.success && orderProductDetails.value.downloaded_files.success['P'+orderProductDetails.value.product_id]">
                                  <tr>
                                    <td class="text-gray-800" v-for="(files, index) in orderProductDetails.value.downloaded_files.success['P'+orderProductDetails.value.product_id]" :key="index"> 
                                          <tr>
                                            <td class="text-gray-800"> 
                                              Successfully Downloaded 
                                            </td>
                                          </tr>
                                        <tr>
                                          <td class="text-gray-800"> 
                                          {{formatText(index)}} : 
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style="padding-left:10px">
                                            <tr class="text-gray-800" v-for="(fileName, fileKey) in files" :key="fileKey">
                                              <template v-if="fileKey != 'usage'">
                                                <td class="text-gray-800"> 
                                                    {{formatText(fileKey)}} : 
                                                </td>
                                                <td class="text-gray-800"> 
                                                  <template v-if="typeof fileName === 'object'">
                                                      <span v-for="(item, itemkey) in fileName" :key="itemkey">
                                                          <a href="javascript:void(0)" @click.prevent="downloadFile('extrafiles/' + item)"> {{itemkey}}</a><br>
                                                      </span>
                                                  </template>
                                                  <template v-else>
                                                      <a href="javascript:void(0)" @click.prevent="downloadFile(fileName)">{{fileName}}</a>
                                                  </template>
                                                </td>
                                              </template>
                                            </tr>
                                          </td>
                                        </tr>
                                    </td>
                                  </tr>
                                </td>
                                <td v-else>
                                  <template v-if="orderProductDetails.value && orderProductDetails.value.downloaded_files && orderProductDetails.value.downloaded_files.failed">
                                    <td class="text-gray-800" v-for="(files, index) in orderProductDetails.value.downloaded_files.failed['P'+orderProductDetails.value.product_id]" :key="index"> 
                                        <tr>
                                          <td class="text-gray-800"> 
                                            Failed to Download 
                                          </td>
                                        </tr>
                                        <tr>
                                          <td class="text-gray-800"> 
                                          {{formatText(index)}} : 
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style="padding-left:10px">
                                            <tr class="text-gray-800" v-for="(fileName, fileKey) in files" :key="fileKey">
                                              <template v-if="fileKey != 'usage'">
                                                <td class="text-gray-800" width="10%"> 
                                                    {{formatText(fileKey)}} : 
                                                </td>
                                                <td class="text-gray-800"> 
                                                  <template v-if="typeof fileName === 'object'">
                                                      <span v-for="(item, itemkey) in fileName" :key="itemkey">
                                                          <a :href="itemkey" target="_blank"> {{itemkey}}</a><br>
                                                      </span>
                                                  </template>
                                                  <template v-else>
                                                      <a :href="fileName" target="_blank">{{fileName}}</a>
                                                  </template>
                                                </td>
                                              </template>
                                            </tr>
                                          </td>
                                        </tr>
                                    </td>
                                  </template>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <!--end::Card header-->
              </div>
              <!--end::General-->
              <!--begin::Extra fields-->
              <div class="card card-flush py-4" v-if="orderProductDetails.value && orderProductDetails.value.extra_fields">
                <!--begin::Card header-->
                <div class="card-header">
                  <div class="card-title">
                    <h2>Extra Fields</h2>
                  </div>
                </div>
                <!--end::Card header-->
                <!--begin::Card body-->
                <div class="card-body pt-0">
                  <div class="mb-10 fv-row">
                    <table class="table table-flush fw-bold gy-1">
                      <tbody>
                        <template v-for="(value, key) in orderProductDetails.value.extra_fields" :key="key"> 
                          <tr v-if="value && value != null && value != ''">
                            <td class="text-muted min-w-200px w-200px">{{formatText(key)}} : </td>
                            <td class="text-gray-800">
                                <template v-if="typeof value === 'object'">
                                <span v-for="(item, itemkey) in value" :key="itemkey">
                                    {{formatText(itemkey)}} : {{item}} <br>
                                </span>
                                </template>
                                <template v-else>
                                {{value}}
                                </template>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!--end::Card header-->
              </div>
              <!--end::Extra fields-->
            </div>
            <!--end::Main column-->
          </div>
          <!--end::div-->
        </div>
        <!--end::Container-->
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import { formatPrice, formatDate, formatDateTime } from "@/core/helpers/common";
import { ElLoading } from "element-plus";
import { useRouter } from "vue-router";
import { notificationFire } from "@/composable/notification.js";
import useEventBus from '../../composable/useEventBus'
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";
import globalConstant from "@/core/data/globalConstant.js";
import { formatText } from "@/core/helpers/common";

import {
  createOrderPoductOptions,
  createOrderPoductFiles
} from "@/composable/order.js";

export default {
  name: "Pending Order Product Details",
  setup() {
    const { onEvent, emitEvent } = useEventBus()
    const router = useRouter();
    const orderId = ref(0);
    const orderProductId = ref(0);
    const orderProductDetails = reactive({});
    const route = useRoute();
    const fullscreenLoading = ref(false);
    const constants = globalConstant;

    let fromRoutePath = "pending-orders";
    let fromRouteName = "pending-orders";
    let fromRoutePathDetails = "pendingOrderDetails";
    let fromRouteNameDetails = "pendingOrderDetails";
    if(route.name == "pending-order-product"){
        fromRoutePath = "pending-order-products";
        fromRouteName = "pending-order-products";
        fromRoutePathDetails = "pending-order-details";
        fromRouteNameDetails = "pending-order-details";
    }

    setCurrentPageBreadcrumbs(formatText(route.name),  [{"name" :formatText(fromRouteName), 'path': fromRoutePath}, {"name" :formatText(fromRouteNameDetails), 'path': fromRoutePathDetails, 'id': orderId.value}, {"name" :"Pending Order Product", active: true}]);

    const getOrderProductDetails = async () => {
      fullscreenLoading.value = true;
      await ApiService.query(
        "pending-orders/product/" + orderId.value + "/" + orderProductId.value
      )
        .then(({ data }) => {
          if (data.data) {
            orderProductDetails.value = data.data;
            let orderfileData = JSON.parse(JSON.stringify(orderProductDetails.value));
            orderfileData.downloaded_files = JSON.stringify(orderProductDetails.value.downloaded_files);
            orderProductDetails.value["product_image"] = createOrderPoductFiles(orderfileData)["productFilesInfo"];
            setCurrentPageBreadcrumbs(formatText(route.name),  [{"name" :formatText(fromRouteName), 'path': fromRoutePath}, {"name" :formatText(fromRouteNameDetails), 'path': fromRoutePathDetails, 'id': orderId.value}, {"name" :"Pending Order Product", active: true}]);
          }
          fullscreenLoading.value = false;
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let status = response.status;
            let message = response.data.message;
            if (status == 404) {
              router.push({ name: "order-list" });
            } else {
              notificationFire(message, "error");
            }
          }
        });
    };

    //Get click event of toolbar buttons
    onEvent("actionName", (actionName) => {
      if (actionName == "back") {
        router.back();
      }
    });

    const downloadFile = (fileName) => {
        if(fileName != ''){
            window.open(process.env.VUE_APP_API_URL + '../../../orders/' + orderId.value + '/' +fileName, '_blank');
        }
    }

    onMounted(() => {
      setCurrentPageActionButtons([{"name" :constants.general.BACK, 'action': 'back','class': 'btn-primary','isLoader': false,'iconClass':"bi bi-arrow-left fs-4" }])
      orderId.value = route.params.order_id;
      orderProductId.value = route.params.product_id;
      getOrderProductDetails();
    });

    return {
      orderId,
      orderProductId,
      orderProductDetails,
      formatPrice,
      formatDate,
      formatDateTime,
      fullscreenLoading,
      formatText,
      downloadFile
    };
  },
};
</script>